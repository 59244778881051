import type { FormatterMap, RouteList } from '@grandvisionhq/frk-routing'
import { createSimpleFormatter } from '@grandvisionhq/frk-routing'
import {
  createAuthFormatters,
  createCheckoutFormatters,
  createMultilangFormatter,
  createMyAccountFormatters,
  productDetailFormatters,
} from '@grandvisionhq/www-next/formatters'

export const excludeSkuFromProductUrls = true

export const localRouteFormatters: FormatterMap = {
  home: createSimpleFormatter('/'),
  'auth.login': createSimpleFormatter('my-account/login'),
  'auth.callback': createSimpleFormatter('my-account/callback'),
  'auth.reset': createSimpleFormatter('my-account/reset'),
  'auth.activate': createSimpleFormatter('my-account/activate'),
  'auth.logout': createSimpleFormatter('my-account/logout'),
  'auth.logged-out': createSimpleFormatter('my-account/logged-out'),
  'cart.overview': createSimpleFormatter('cart'),
  'appointments.book': createMultilangFormatter({
    'nl-BE': 'afspraak-maken',
    'fr-BE': 'fixez-un-rendez-vous',
  }),
  'appointments.cancel': createSimpleFormatter('cancel-appointment'),
  'appointments.reschedule': createSimpleFormatter('reschedule-appointment'),
  ...createMyAccountFormatters(),
  ...createAuthFormatters(),
  'express-checkout': createSimpleFormatter('express-checkout'),
  'order.confirm': createSimpleFormatter('order/confirm'),
  ...createCheckoutFormatters({
    path: 'checkout',
    children: { login: 'login', shipping: 'shipping', overview: 'overview', payment: 'payment' },
  }),
  ...productDetailFormatters(
    {
      'nl-BE': {
        'contact-lenses': 'contactlenzen',
        frames: 'brillen',
        solutions: 'contactlenzen/vloeistof',
        sunglasses: 'zonnebrillen',
        'ready-readers': 'brillen/kant-en-klare-leesbrillen',
      },
      'fr-BE': {
        'contact-lenses': 'lentilles',
        frames: 'lunettes',
        solutions: 'lentilles/produits-entretien',
        sunglasses: 'solaires',
        'ready-readers': 'lunettes/de-lecture-pretes-a-emploi',
      },
    },
    { excludeSku: excludeSkuFromProductUrls }
  ),
  wishlist: createSimpleFormatter('/wishlist'),
}

export const routes: RouteList = [
  ['/', 'home'],

  ['/checkout/login', 'checkout.login'],
  ['/checkout/shipping', 'checkout.shipping'],
  ['/checkout/overview', 'checkout.overview'],
  ['/checkout/payment', 'checkout.payment'],
  ['/api/payment-callback', 'payment.callback'],
  ['/order/confirm', 'order.confirm'],
]
